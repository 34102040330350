import { gql } from "../../graphql-types";

export const INVESTMENT_COMPLETENESS_QUERY = gql(`
  query investmentCompleteness($investmentId: Int!) {
    investment(id: $investmentId) {
      completeness(memoized: false) {
        category
        subcategory
        level
        weight
        percentComplete
        description
      }
    }
  }
`);
