import { useQuery } from "@apollo/client";
import HighChartsReact from "highcharts-react-official";
import * as HighCharts from "highcharts/highcharts";
import highChartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import { useMemo } from "react";
import { useCurrentUser } from "../../utils/hooks";
import { INVESTMENT_COMPLETENESS_QUERY } from "./graphql";

if (typeof HighCharts === "object" && typeof window !== "undefined") {
  highChartsMore(HighCharts);
  solidGauge(HighCharts);
}
if (HighCharts === undefined) throw new Error("HighCharts is not defined");

declare interface CustomPoint extends HighCharts.Point {
  custom?: {
    totalY: number;
    percentComplete: string;
  };
  innerRadius: string;
  radius: string;
}
declare interface CustomSeriesGaugeOptions
  extends HighCharts.SeriesSolidgaugeOptions {
  custom: {
    startAngle: number;
    endAngle: number;
  };
  data: [CustomPoint];
}

declare interface ChartTooltip
  extends Omit<HighCharts.TooltipOptions, "pointFormatter"> {
  pointFormatter: HighCharts.FormatterCallbackFunction<CustomPoint>;
}

declare interface ChartOptions extends Omit<HighCharts.Options, "tooltip"> {
  tooltip: ChartTooltip;
}

function InvestmentCompletenessChart({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const currentUser = useCurrentUser();

  const { data, loading } = useQuery(INVESTMENT_COMPLETENESS_QUERY, {
    variables: { investmentId },
    skip: !currentUser?.flags.isRockCreek,
  });

  const chartSeries = useMemo<CustomSeriesGaugeOptions[]>(() => {
    const completeness = data?.investment.completeness;
    if (!completeness) return [];
    let level3Count = 0;

    const level2Indexes = completeness.flatMap((x, i) =>
      x.level === 2 ? i : []
    );

    return level2Indexes.flatMap((level2Index, ilevel2Index) => {
      let cumulative = 0;
      const level2 = completeness[level2Index];
      const level3s = completeness.slice(
        level2Index + 1,
        level2Indexes[ilevel2Index + 1]
      );
      const totalWeight = level3s.reduce((sum, x) => sum + x.weight, 0);
      return level3s.map(level3 => {
        ++level3Count;
        const RING_WIDTH = 10;
        const RING_DIAMETER = 100;
        const EMPTY_RING_SIZE = 5;

        const value = (level3.weight * level3.percentComplete) / totalWeight;
        const level3Value = Math.max(value * 100, EMPTY_RING_SIZE);
        cumulative += level3Value;

        const startAngle = ((cumulative - level3Value) / 100) * 360;
        const endAngle = startAngle + 360;

        const innerRadius = `${RING_DIAMETER - ilevel2Index * RING_WIDTH}%`;
        const radius = `${RING_DIAMETER - (ilevel2Index + 1) * RING_WIDTH}%`;

        const completenessColor =
          (HighCharts.getOptions()?.colors || [])[ilevel2Index] || "#000000";

        return {
          name: level2.category,
          type: "solidgauge",
          subCategory: level3.subcategory,
          custom: {
            startAngle,
            endAngle,
          },
          yAxis: level3Count - 1,
          data: [
            {
              name: level3.subcategory,
              y: level3Value,
              innerRadius,
              radius,
              custom: {
                totalY: level2.percentComplete * 100,
                percentComplete: (level3.percentComplete * 100).toFixed(0),
              },
              color: value
                ? completenessColor
                : new HighCharts.Color(completenessColor).setOpacity(0.3).get(),
            },
          ] as [CustomPoint],
        } as CustomSeriesGaugeOptions;
      });
    });
  }, [data]);

  const options = useMemo<ChartOptions>(() => {
    const percentComplete =
      (data?.investment.completeness?.[0].percentComplete ?? 0) * 100;

    return {
      credits: {
        enabled: false,
      },
      chart: {
        backgroundColor: "transparent",
        type: "solidgauge",
        width: 150,
        height: 200,
        margin: [0, 0, 0, 0], // Top, Right, Bottom, Left margins
        spacing: [0, 0, 0, 0],
      },
      title: {
        text: "Data Coverage",
        style: {
          fontSize: "14px",
        },
        y: 20,
      },

      subtitle: {
        text: `${percentComplete.toFixed(0)}%`,

        style: {
          fontSize: "14px",
          //share color between gray and green be a range of colors based on percent complete
          color: percentComplete > 50 ? "#4CAF50" : "#9E9E9E",
        },
        y: 105,
      },

      tooltip: {
        borderWidth: 0,
        backgroundColor: "none",
        shadow: false,
        style: {
          fontSize: "12px",
        },
        valueSuffix: "%",
        pointFormatter: function () {
          if (this.name) {
            return `
            <br><span style="color:${this.color};font-size: 1em;">${
              this.series.name
            }</span>: <b>${this.custom?.totalY.toFixed(0)}%</b><br><i>${
              this.name
            }</i>:<b> ${this.custom?.percentComplete}%</b>
            `;
          } else {
            return `${this.series.name}:<b> ${this.custom?.totalY.toFixed(
              0
            )}%</b><br>`;
          }
        },

        positioner: function (labelWidth) {
          return {
            x: (this.chart.chartWidth - labelWidth) / 2,
            y: this.chart.plotHeight / 2 + 58,
          };
        },
      },

      pane: chartSeries.map((item, i) => ({
        startAngle: item.custom.startAngle,
        endAngle: item.custom.endAngle,
        background: [
          {
            outerRadius: item.data[0].radius,
            innerRadius: item.data[0].innerRadius,
            borderWidth: 0,
          },
        ],
      })),

      yAxis: chartSeries.map((item, i) => ({
        min: 0,
        max: 100,
        pane: i,
        lineWidth: 0,
        tickPositions: [],
      })),

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      exporting: {
        enabled: false,
      },

      series: chartSeries,
    } as ChartOptions;
  }, [chartSeries, data]);

  if (!data) return <> </>;
  if (!currentUser?.flags.isRockCreek) return <> </>;
  //display centered
  return (
    <div
      style={{ display: "flex", justifyContent: "center" }}
      data-cy="data-coverage"
    >
      <HighChartsReact
        loading={loading}
        constructorType="chart"
        options={options}
        highcharts={HighCharts}
      />
    </div>
  );
}

export default InvestmentCompletenessChart;
